import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import Error from '@material-ui/icons/Error';
import { createBrowserHistory } from 'history';
import { ToastContainer, toast } from 'react-toastify';
import CheckCircle from '@material-ui/icons/CheckCircle';
import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import SignIn from './SignIn';
import Dashboard from './Dashboard';
import NewMigration from './NewMigration';
import PublicRoute from '../store/PublicRoute';
import Preloader from '../components/preloader';
import PrivateRoute from '../store/PrivateRoute';
import { signOut, signIn } from '../store/actions/Auth';

import '../static/css/style.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-toastify/dist/ReactToastify.css';

const hist = createBrowserHistory();
const BounceLoader = lazy(() => import('../components/bounceloader'));

class App extends Component {

  componentDidMount() {
    EventBus.on('tokenExpired', () => this.props.signOut());
    EventBus.on('info', (e) => toast.info(() => <div> <Error /> {e}</div>));
    EventBus.on('error', (e) => toast.error(() => <div> <Error /> {e}</div>));
    EventBus.on('success', (e) => toast.success(() => <div> <CheckCircle /> {e}</div>));
  };

  render() {
    return (
      <div>
        <Suspense fallback={<Preloader />}>
          <BounceLoader />
          <ToastContainer closeOnClick position="bottom-left" />
          <Router history={hist}>
            <Switch>
              <PublicRoute exact path='/' component={props => <SignIn {...props} />} />
              <PublicRoute exact path='/SignIn' component={props => <SignIn {...props} />} />
              <PublicRoute exact path='/hubLogin' component={props => <SignIn {...props} />} />
              <PublicRoute exact path='/hubRedirect' component={props => <SignIn {...props} />} />
              <PublicRoute exact path='/connectAuthHubspot' component={props => <SignIn {...props} />} />
              <PublicRoute exact path='/hubspotAuthenticate' component={props => <SignIn {...props} />} />

              <PrivateRoute exact path='/Dashboard' component={props => <Dashboard {...props} />} />
              <PrivateRoute exact path='/NewMigration' component={props => <NewMigration {...props} />} />
              <PrivateRoute exact path='/hubspotAuth' component={props => <NewMigration {...props} />} />
              <PrivateRoute exact path='/salesforceAuth' component={props => <NewMigration {...props} />} />
              {/* <PrivateRoute exact path='/Connect' component={props => <Connect {...props} />} /> */}
            </Switch>
          </Router>
        </Suspense>
      </div>
    );
  }
}

const mapDispatchToProps = { signOut, signIn };

const mapStateToProps = ({ Auth }) => {
  let { publicAddress } = Auth;
  return { publicAddress }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);